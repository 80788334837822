import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="Footer">
      <p>2024 | &copy; Onzima Construction </p>
      <p>14 Oak Ave, Primrose, Germiston, 1416</p>
    </div>
  );
};

export default Footer;